import { Center, Container } from '@chakra-ui/react'
// import { ErrorPageTemplate } from 'design_system/modules/ErrorPageTemplate/ErrorPageTemplate'
// import { getServerData } from 'helper/serverData'
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

export const getStaticProps: GetStaticProps = async (context) => {
  // const [serverData] = await Promise.all([getServerData()])
  return {
    props: {
      revalidateTime: Date.now(),
      // ...serverData,
    },
    revalidate: 600,
  }
}

const Error404: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = () => {
  const router = useRouter()
  const intl = useIntl()
  return (
    <Container maxW="container.content" py={{ base: '50px', md: '100px', lg: '150px', xl: '200px' }}>
      <Center textAlign="center">
        404
        {/* TODO: Implement ErrorPageTemplate from design system */}
        {/* <ErrorPageTemplate
          heading={{
            text: intl.formatMessage({ id: '404--heading' }),
            type: 'h2',
            color: 'accent.500',
          }}
          buttonProps={[
            { colorScheme: 'accent', label: intl.formatMessage({ id: '--previous-page' }), onClick: () => router.back() },
            { label: intl.formatMessage({ id: '--back-to-homepage' }), onClick: () => router.push('/') },
          ]}
          customContent={intl.formatMessage({ id: '404--content' })}
          errorCode={404}
        /> */}
      </Center>
    </Container>
  )
}

export default Error404
